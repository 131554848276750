import React from 'react'
import Layout from "../components/App/Layout"
import Navbar from "../components/App/Navbar"
import Footer from "../components/App/Footer"
import ServicesOne from '../components/Services/ServicesOne'
import Banner from "../components/Services/Banner"
import Faq from "../components/Services/faq"

import ProjectStartArea from "../components/Index/ProjectStartArea"

const Services = () => {
    return (
        <Layout>
            <Navbar />
            <Banner />
            <ServicesOne />
            <Faq />
            <ProjectStartArea />
            <Footer />
        </Layout>
    );
}

export default Services