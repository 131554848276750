import React from 'react'
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemPanel,
    AccordionItemButton
} from 'react-accessible-accordion'

import starIcon from '../../assets/images/star-icon.png'

const FAQ = () => {
    return (
        <section className="solutions-area pt-70 pb-100 bg-f1f8fb">
            <div className="container">
                <div className="section-title pb-40">
                    <span className="sub-title">
                        <img src={starIcon} alt="blog" />
                        QnA
                    </span>
                    <h2>Questions & Answers</h2>
                    <p>Take a look at how we solve challenges to meet project requirements.</p>
                </div>
                <div className="faq-accordion">
                    <Accordion>
                        <AccordionItem>
                            <AccordionItemHeading>
                                <AccordionItemButton>
                                    Q1. I have an idea only. What makes sense as the next step?
                                    </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                                <p> 
                                    The next step is market research to make sure your idea has a working potential. Business analysis is also necessary to build a right business strategy. Next, all your requirements to your product should be well-documented in functional specification that will describe the technical methods of implementing your business goals.
                                </p>
                            </AccordionItemPanel>
                        </AccordionItem>

                        <AccordionItem>
                            <AccordionItemHeading>
                                <AccordionItemButton>
                                    Q2. If I have design ready, can you start right from app development?
                                    </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                                <p>
                                Yes, we can. We are ready to use your materials as a foundation of a future app. However, we will have to revise everything you will provide us with to make sure we eliminate the risks of failure. All possible downsides are improved by our experienced team members.
                                </p>
                            </AccordionItemPanel>
                        </AccordionItem>

                        <AccordionItem>
                            <AccordionItemHeading>
                                <AccordionItemButton>
                                    Q3. Can ITSAS help improve my existing app?
                                    </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                                <p>
                                Yes. ITSAS has already delivered projects based on existing applications. Here are the services we can offer to improve your app:
develop a new set of features;
create a new version of your app for another platform;
make a redesign of your app;
your project recovery (if you have a ghost project, we can help you make it work).
                                </p>
                            </AccordionItemPanel>
                        </AccordionItem>

                        <AccordionItem>
                            <AccordionItemHeading>
                                <AccordionItemButton>
                                    Q4. Can I hire ITSAS developers by the hour?
                                    </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                                <p>
                                You can contact us and we’ll discuss everything you want to know about our development service offers and how you can employ them.
                                </p>
                            </AccordionItemPanel>
                        </AccordionItem>

                        <AccordionItem>
                            <AccordionItemHeading>
                                <AccordionItemButton>
                                    Q5. I don't have enough budget for my project. Can we partner for equity?
                                    </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                                <p>
                                I'm afraid, no.
                                </p>
                            </AccordionItemPanel>
                        </AccordionItem>
                    </Accordion>
                </div>
            </div>
        </section>
    );
}

export default FAQ